import shouldHide from './hiddenItems';
import removeInvalidLocations from './common/removeInvalidLocations';

export function groupByLocations(variantLocations, settings) {
  if (shouldHide(settings)) {
    return null;
  }
  return variantLocations.reduce(function(accumulator, variantLocation) {
    const validLocations = removeInvalidLocations(
      variantLocation.inventoryLocations,
      settings
    );

    validLocations.forEach(function(inventoryLocation) {
      if (!accumulator[inventoryLocation.location.id]) {
        accumulator[inventoryLocation.location.id] = {
          location: inventoryLocation.location,
          variants: []
        };
      }
      const variant = getVariant(variantLocation, inventoryLocation, settings);
      if (variant) {
        accumulator[inventoryLocation.location.id].variants.push(variant);
      }
    });
    return accumulator;
  }, {});
}

function getVariant(variantLocation, inventoryLocation, settings) {
  const variantDetails = window.inventoryInfo.product.variants.find(function(
    v
  ) {
    return v.id === variantLocation.variant;
  });
  return settings.hideSoldOut && inventoryLocation.quantity <= 0
    ? undefined
    : {
        id: variantLocation.variant,
        policy: variantLocation.policy,
        inventoryManagement: variantLocation.inventoryManagement,
        quantity: inventoryLocation.quantity,
        name: window.inventoryInfo.product.defaultVariantOnly
          ? ''
          : variantDetails.title,
        incoming: variantDetails.incoming,
        available: variantDetails.available
      };
}
