export const Type = {
  LIST: 1,
  LOCATION_SEGMENTS: 2
};

export const FulfillmentRule = {
  SELECTED_LOCATION: 1
};

export const InventoryDisplayOption = {
  OutOfStockAndHasIncoming: 1,
  HasIncoming: 2,
  Alwyas: 3
};

export const DefautlColors = {
  iconColor: '#5C5F62'
};

export const Tokens = {
  qty: '{{qty}}',
  locationId: '{{location-id}}',
  locationName: '{{location-name}}',
  header: '{{header}}',
  decription: '{{description}}',
  titleText: '{{titleText}}',
  address1: '{{address1}}',
  address2: '{{address2}}',
  province: '{{province}}',
  city: '{{city}}',
  zip: '{{zip}}',
  distance: '{{distance}}',
  phone: '{{phone}}',
  info: '{{info}}',
  expandable: '{{expandable}}',
  iconcolor: '{{iconcolor}}',
  note: '{{note}}',
  product: '{{product}}',
  online: ' {{online}}',
  storeLocationHeader: '{{storeLocationHeader}}',
  onlineLocationHeader: '{{onlineLocationHeader}}',
  disabled: '{{disabled}}',
  threshold: '{{threshold-message}}',
  thresholdColor: '{{thresholdColor}}',
  thresholdColorStyle: '{{thresholdColorStyle}}',
  incomingStock: '{{incoming-stock}}',
  selectLocationInfo: '{{selected-location-info}}',
  incomingStockDrawer: '{{incoming-stock-drawer}}',
  productTitle: '{{product-title}}',
  variantTitle: '{{variant-title}}',
  incomingStockLink: '{{incoming-stock-link}}',
  drawerLabel: '{{drawerLabel}}',
  incomingStockNote: '{{incoming-stock-note}}',
  headerTextTtemplate: '{{header-text-title}}',
  options: '{{options}}',
  children: '{{children}}',
  name: '{{name}}'
};

export const DefaulText = {
  available: 'available',
  header: 'Availability',
  DefaultLabel: 'Delivery only',
  Description: '',
  OnlineLocation: 'Online',
  StockMessageError: `Invalid rule found in inventory threshold. 
    <br/> Please refer to
   <a target="_blank" href="https://support.locationinventory.info/invalid-rule">
    How to fix invalid rule error
   </a>`,
  AtcBlockedMsg: `You cannot add more ${Tokens.product}, Qty available: ${Tokens.qty}.`,
  Incoming: `Incoming - ${Tokens.qty}`
};

export const CssClasses = {
  expandable: ' iia-expandable',
  clickable: '.iia-clickable',
  chevron: '.iia-chevron',
  chevronUp: 'iia-chevron-up',
  chevronDown: 'iia-chevron-down',
  detailsContainer: '.iia-details-container',
  display: 'iia-display',
  regionalMsg: 'iia-regional-message',
  drawerBody: 'iia-drawer-body'
};

export const HtmlFragments = {
  locationName: `<span class="iia-location">${Tokens.locationName}</span>`,
  name: `<span class="iia-location iia-name">${Tokens.name}</span>`,
  qty: `<span class="iia-qty">${Tokens.qty}</span>`,
  stockMsgError: `<span class="iia-stockMsg-error">${DefaulText.StockMessageError}</span>`,
  threshold: `<span class='iia-stock-threshold' ${Tokens.thresholdColorStyle}>${Tokens.threshold}</span>`,
  thresholdColorStyle: `style=color:${Tokens.thresholdColor};`
};

export function wrapTextTemplatesWithHtml(textTemplate) {
  if (textTemplate.indexOf('</') < 0) {
    return textTemplate
      .replace(Tokens.locationName, HtmlFragments.locationName)
      .replace(Tokens.name, HtmlFragments.name)
      .replace(Tokens.qty, HtmlFragments.qty)
      .replace(Tokens.threshold, HtmlFragments.threshold);
  }
  return textTemplate;
}
